import React from 'react'

import {
    Title
} from '../../components'

export default function(){
    return (
        <>
            <Title>Como funcionam as expansões</Title>

            <div className="row text-white mt-5">
                <div className="col">
                    <p>Uma vez por semana, em consulta de retorno, a equipe médica punciona a válvula com uma pequena agulha e coloca soro fisiológico no expansor para esticar a pele e o músculo.</p>
                    <p>Pode ser necessário o uso de medicações para dor no dia da expansão e no dia seguinte. A quantidade de soro colocado depende do incômodo sentido e de quanto a pele e o músculo aguentam (para não ficar muito esticado).</p>
                    <p>Quando o expansor estiver cheio é feita uma segunda cirurgia para retirá-lo e colocar uma prótese de silicone no lugar. Geralmente, essa programação ocorre no mínimo 6 meses após terminar os tratamentos complementares (quimioterapia e/ou radioterapia).</p>
                </div>
            </div>
        </>
    )
}