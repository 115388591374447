import React from 'react'
import { SubTitle, Title } from '../../components'

import tabagismo from '../../assets/tabagismo.jpg'
import dreno from '../../assets/dreno.jpg'
import medicacao from '../../assets/medicacao.jpg'
import { useParams } from 'react-router-dom'

export default function(){

    const cuidadosList = [
        {
            title: "Tabagismo",
            text: "Se você fuma, seu cirurgião provavelmente vai pedir que você pare de fumar por pelo menos três semanas antes e três semanas depois da cirurgia, pois o cigarro diminui a circulação de sangue na pele, piora a cicatrização e aumenta o risco de complicações.",
            image: tabagismo,
            tram: false
        },
        {
            title: "Alimentação",
            text: "Não há comprovação científica de nenhum alimento estritamente proibido no pós-operatório. Porém, é importante manter uma alimentação saudável e beber bastante líquido. Evitar gorduras, frituras e comidas industrializadas. Evitar bebidas alcoólicas nas primeiras semanas.",
            image: null,
            tram: false
        },
        {
            title: "Banho",
            text: "É importante ter alguém para ajudá-la nos primeiros banhos. Coloque uma cadeira ou banco ao seu lado, para ter onde sentar caso sinta tontura. Lave a região da cirurgia com água do chuveiro em temperatura morna, sem esfregar os pontos. Utilize sabonetes que você já esteja acostumada. Seque cuidadosamente a área operada com uma toalha macia.",
            image: null,
            tram: false
        },
        {
            title: "Cuidados com a mama e curativos",
            text: "É importante fazer a limpeza da área operada todos os dias e um novo curativo conforme orientado pela equipe médica. Só utilize pomadas, cremes ou loções se indicado pelo médico. Não tome sol sobre a área operada. Pergunte ao seu médico se será necessário o uso de algum sutiã especial.",
            image: null,
            tram: false
        },
        {
            title: "Dreno",
            text: "Um dreno pode ser temporariamente colocado embaixo da pele para ajudar a não acumular sangue ou líquidos. Você deve esvaziar o dreno uma vez ao dia ou conforme orientação de seu cirurgião e anotar a quantidade de líquido que saiu. O dreno será retirado em consulta de retorno, geralmente quando drena menos de 30 a 50 ml em 24 horas, ou a critério do cirurgião.",
            image: dreno,
            tram: false
        },
        {
            title: "Uso de medicações e antibióticos",
            text: "Converse com seu médico sobre as medicações que você já toma todos os dias, se será necessário parar de tomar e quando retornar. Informe se já teve alguma alergia a remédios. Medicações para dor (analgésicos) serão receitados no momento da alta. Nos primeiros dias de pós-operatório podem ser usadas em horários fixos para que você não sinta dor e depois apenas se necessário. Antibióticos podem ser receitados de acordo com o tipo de cirurgia, uso de drenos e implantes e de acordo com a indicação da equipe médica. Respeite os horários e os dias que foram orientados. Não pare de tomar antes do tempo recomendado. Se você apresentar alguma reação ou efeito colateral avise a equipe médica.",
            image: medicacao,
            tram: false
        },
        {
            title: "Movimentação",
            text: "É importante evitar ficar apenas deitada. Caminhe pela casa para diminuir o risco de trombose nas pernas, se seu cirurgião disser que você pode. Meias elásticas poderão ser indicadas dependendo de cada caso. Movimente os pés e as pernas enquanto estiver deitada ou sentada. Você pode fazer movimentos para frente e para trás com os pés e girá-los de um lado ao outro. Não pegue peso e não faça esforço com o braço do lado da mama operada. Os movimentos com os braços devem ser lentos e progressivos, conforme orientações do seu médico. Os movimentos permitidos dependerão do tipo da cirurgia realizada. Se foi realizada a retirada dos “gânglios” da axila deve-se evitar usar as veias (tirar sangue, passar medicamentos) e medir a pressão no braço deste lado. O retorno ao trabalho dependerá do tipo de cirurgia e do tipo de trabalho que você realiza. Pergunte ao seu médico quando poderá voltar a trabalhar. O tempo para voltar a dirigir também dependerá do tipo de cirurgia realizada. A realização de fisioterapia e drenagem linfática deve ser discutida com seu médico e, se indicadas, devem ser realizadas por profissional habilitado.",
            image: null,
            tram: false
        },
        {
            title: "Movimentação - TRAM",
            text: "Cuidados com a barriga: Mantenha o uso da cinta elástica conforme orientações. Tenha cuidado para não ficar muito apertada. Enquanto estiver de pé ande com o corpo inclinado levemente para frente, nos primeiros 15 dias. Durma de barriga para cima e com um travesseiro ou almofada embaixo dos joelhos. Não pegue peso e tenha cuidado com as atividades que forcem o abdome.",
            image: null,
            tram: true
        }
    ]

    const {tram} = useParams()
    return (
        <>
            <Title>Cuidados Pós-Operatórios</Title>
            <div className="row">
                <div className="col text-white mt-5">
                    <p>
                        Como você vai se sentir após a cirurgia vai depender do tipo de cirurgia que foi feita para retirada do tumor e o tipo de reconstrução.
                    </p>
                    <p>
                        Seu cirurgião irá lhe explicar sobre a cirurgia e sobre os cuidados pós- operatórios, incluindo: como cuidar de sua mama reconstruída após a cirurgia, cuidados com sua saúde geral, os medicamentos que deve tomar para ajudar a diminuir a dor e o risco de infecção, cuidados com os pontos e o local da cirurgia,além da orientação sobre as consultas de retorno. Não deixe de pedir para que alguém a acompanhe ao hospital e que a ajude em casa, se necessário.
                    </p>

                    <ul>
                        {
                            cuidadosList.map(cuidado => {
                                if(!cuidado.tram){
                                    return (
                                        <li className="mt-5" key={cuidado.title}>
                                            <SubTitle>{cuidado.title}</SubTitle>
                                            <p>{cuidado.text}</p>
                                            {
                                                cuidado.image !== null ? (
                                                    <img className="img-fluid mt-5" src={cuidado.image} alt={cuidado.title} />
                                                ) : null
                                            }
                                        </li>
                                    )
                                }else{
                                    if(tram != null){
                                        return (
                                            <li className="mt-5" key={cuidado.title}>
                                                <SubTitle>{cuidado.title}</SubTitle>
                                                <p>{cuidado.text}</p>
                                                {
                                                    cuidado.image !== null ? (
                                                        <img className="img-fluid mt-5" src={cuidado.image} alt={cuidado.title} />
                                                    ) : null
                                                }
                                            </li>
                                        )
                                    }else{
                                        return null
                                    }
                                }
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}