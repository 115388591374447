import React from 'react'

import {
  ImageBackground,
  DividerPage
} from '../index'

import {
  HomePage,
  Introduction
} from '../../pages'

import {
  Switch,
  Route, useLocation, useHistory
} from 'react-router-dom'

import postop from '../../assets/logo-white.png'
import MainMenu from '../../pages/MainMenu'
import SiliconeExpansor from '../../pages/SiliconeExpansor'
import Retalhos from '../../pages/Retalhos'
import SiliconeImediata from '../../pages/SiliconeImediata'
import SiliconeImediataDescricao from '../../pages/SiliconeImediata/SiliconeImediataDescricao'
import ExpansorTecidos from '../../pages/ExpansorTecidos'
import PosOperatorio from '../../pages/PosOperatorio'
import SimetrizacaoMama from '../../pages/SimetrizacaoMama'
import ReconstrucaoAreolaMamilo from '../../pages/ReconstrucaoAreolaMamilo'
import Intercorrencias from '../../pages/Intercorrencias'
import Button from '../Button'
import ExpansorTecidosDescricao from '../../pages/ExpansorTecidos/ExpansorTecidosDescricao'
import ComoFunciona from '../../pages/ExpansorTecidos/ComoFunciona'
import RetalhoLocalRegional from '../../pages/RetalhoLocalRegional'
import RetalhoLocalRegionalDescricao from '../../pages/RetalhoLocalRegional/RetalhoLocalRegionalDescricao'
import RetalhoMusculoDorsal from '../../pages/RetalhoMusculoDorsal'
import RetalhoMusculoDorsalDescricao from '../../pages/RetalhoMusculoDorsal/RetalhoMusculoDorsalDescricao'
import RetalhoTram from '../../pages/RetalhoTram'
import RetalhoMicrocirurgico from '../../pages/RetalhoMicrocirurgico'
import RetalhoTramDescricao from '../../pages/RetalhoTram/RetalhoTramDescricao'
import RetalhoMicroscirurgicoDescricao from '../../pages/RetalhoMicrocirurgico/RetalhoMicroscirurgicoDescricao'
import SubstituicaoExpansor from '../../pages/SubstituicaoExpansor'

export default function () {

  const location = useLocation()
  const history = useHistory()
  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col mt-5">
            <img className="img-fluid" src={postop} alt="POST-OP" />
          </div>
        </div>
      {
        location.pathname === "/" ? <ImageBackground /> : null
      }
          
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>

            <Route path="/intro" exact>
              <Introduction />
            </Route>

            <Route path="/mainmenu" exact>
              <MainMenu />
            </Route>

            <Route path="/siliconeexpansormenu" exact>
              <SiliconeExpansor />
            </Route>

            <Route path="/retalhosmenu" exact>
              <Retalhos />
            </Route>

            <Route path="/siliconeimediata" exact>
              <SiliconeImediata />
            </Route>

            <Route path="/expansortecidos" exact>
              <ExpansorTecidos />
            </Route>

            <Route path="/expansortecidos/descricao" exact>
              <ExpansorTecidosDescricao />
            </Route>

            <Route path="/expansortecidos/comofunciona" exact>
              <ComoFunciona />
            </Route>

            <Route path="/siliconeimediata/descricao" exact>
              <SiliconeImediataDescricao />
            </Route>

            <Route path="/posOperatorio/:tram" exact>
              <PosOperatorio />
            </Route>

            <Route path="/posOperatorio" exact>
              <PosOperatorio />
            </Route>

            <Route path="/simetrizacaoMama" exact>
              <SimetrizacaoMama />
            </Route>

            <Route path="/reconstrucaoAreolaMamilo" exact>
              <ReconstrucaoAreolaMamilo />
            </Route>

            <Route path="/intercorrencias" exact>
              <Intercorrencias />
            </Route>

            <Route path="/retalholocal" exact>
              <RetalhoLocalRegional />
            </Route>

            <Route path="/retalholocal/descricao" exact>
              <RetalhoLocalRegionalDescricao />
            </Route>

            <Route path="/retalhodorsal" exact>
              <RetalhoMusculoDorsal />
            </Route>

            <Route path="/retalhodorsal/descricao" exact>
              <RetalhoMusculoDorsalDescricao />
            </Route>
            
            <Route path="/retalhotram" exact>
              <RetalhoTram />
            </Route>

            <Route path="/retalhotram/descricao" exact>
              <RetalhoTramDescricao />
            </Route>
            
            <Route path="/retalhomicrocirurgico" exact>
              <RetalhoMicrocirurgico />
            </Route>

            <Route path="/retalhomicrocirurgico/descricao" exact>
              <RetalhoMicroscirurgicoDescricao />
            </Route>

            <Route path="/substituicaoexpansor" exact>
              <SubstituicaoExpansor />
            </Route>
          </Switch>

          {
            location.pathname !== "/" ? (
              <div className="row">
                <div className="col text-center mt-5 mb-5">
                  <Button onClick={() => {
                    history.goBack()
                  }}>Voltar</Button>
                </div>
              </div>
            ) : null
          }

          
      </div>
      {
        location.pathname === "/" ? null : <DividerPage />
      }
    </>
  )
}
