import React from 'react'

import styles from './styles.module.css'

import background from '../../assets/background1.png'

export default function (){
    return (
        <img className={styles.background + " img-fluid"} src={background} alt="Background" />
    )
}