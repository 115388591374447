import React from 'react'

import {
    Title
} from '../../components'

export default function(){
    return (
        <>
            <Title>Simetrização da mama</Title>
            <div className="row mt-5">
                <div className="col text-white">
                    <p className="mt-4">Muitas vezes a mama que não teve a doença também precisa de uma cirurgia para se tornar mais parecida com a mama que foi operada.</p>
                    <p className="mt-4">Mamas pequenas podem precisar de próteses de silicone, mamas grandes podem ser reduzidas (mamoplastia redutora) e mamas caídas ou flácidas podem ser levantadas (mastopexia).</p>
                    <p className="mt-4">Para corrigir algumas depressões na mama, o cirurgião plástico pode utilizar gordura da própria paciente (retirada por lipoaspiração), e enxertar em locais específicos.</p>
                </div>
            </div>
        </>
    )
}