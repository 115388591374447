import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    Button,
    Title
} from '../../components'

export default function(){
    const history = useHistory()
    return (
        <>
            <Title>Escolha uma opção</Title>
            <div className="text-center mt-5">
                <Button onClick={() => {
                    history.push("/siliconeimediata")
                }} fullwidth>Prótese de silicone imediata</Button>

                
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/expansortecidos")
                    }
                } fullwidth>Reconstrução com expansor de tecidos</Button>
            </div>
        </>
    )
}