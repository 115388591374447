import React from 'react'

import styles from './styles.module.css'

export default function(){
    return(
        <>
            <div className={styles.dividerTop}></div>
            <div className={styles.divider}></div>
        </>
    )
}