import React from 'react'
import { Title } from '../../components'

import img3 from '../../assets/img3.jpg'
import img4 from '../../assets/img4.jpg'

export default function(){
    return (
        <>
            <Title>O que é?</Title>

            <div className="row mt-5 text-white">
                <div className="col">
                    <p>O músculo Latíssimo do Dorso ou Grande Dorsal é um grande músculo localizado nas costas. A reconstrução da mama pode ser feita trazendo este músculo, normalmente com um pouco de pele das costas por debaixo da região da axila . Ele ficará posicionado no lugar da mama operada. Embaixo dele será colocada uma prótese de silicone para dar volume.</p>

                    <img className="img-fluid mt-5 mb-3" src={img3} alt="Grande Dorsal" />
                    <img className="img-fluid" src={img4} alt="Grande Dorsal" />
                </div>
            </div>
        </>
    )
}