import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    Button,
    Title
} from '../../components'

export default function(){
    const history = useHistory()
    return (
        <>
            <Title>Escolha uma opção</Title>
            <div className="text-center mt-5">
                <Button onClick={() => {
                    history.push("/expansortecidos/descricao")
                }} fullwidth>O que é</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={() => {
                    history.push("/expansortecidos/comofunciona")
                }} fullwidth>Como funcionam as expansões</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/posOperatorio")
                    }
                } fullwidth>Cuidados pós-operatórios</Button>
            </div>

            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/substituicaoexpansor")
                    }
                } fullwidth>Substituição do expansor por prótese</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/simetrizacaoMama")
                    }
                } fullwidth>Simetrização da outra mama</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/reconstrucaoAreolaMamilo")
                    }
                } fullwidth>Reconstrução da aréola e mamilo</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/intercorrencias")
                    }
                } fullwidth>Possíveis Intercorrências ou complicações</Button>
            </div>
        </>
    )
}