import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    Button,
    Title
} from '../../components'

export default function(){
    const history = useHistory()
    return (
        <>
            <Title>Escolha uma opção</Title>
            <div className="text-center mt-5">
                <Button onClick={() => {
                    history.push("/siliconeexpansormenu")
                }} fullwidth>Reconstrução com prótese de silicone/expansor</Button>

                
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/retalhosmenu")
                    }
                } fullwidth>Reconstrução com retalhos</Button>
            </div>
        </>
    )
}