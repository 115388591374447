import React from 'react'
import { Title } from '../../components'

export default function(){
    return (
        <>
            <Title>O que é?</Title>

            <div className="row mt-5 text-white">
                <div className="col">
                    <p>Nestes casos a reconstrução precisa de técnicas avançadas (com uso de microscópios) para reconstruir a mama, utilizando pele e gordura de outras regiões do corpo. Por serem muito complexas, essas cirurgias são realizadas somente em hospitais especializados.</p>
                </div>
            </div>
        </>
    )
}