import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    Button,
    Title
} from '../../components'

export default function(){
    const history = useHistory()
    return (
        <>
            <Title>Escolha uma opção</Title>
            <div className="text-center mt-5">
                <Button onClick={() => {
                    history.push("/retalhotram/descricao")
                }} fullwidth>O que é</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/posOperatorio/tram")
                    }
                } fullwidth>Cuidados pós-operatórios</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/simetrizacaoMama")
                    }
                } fullwidth>Simetrização da outra mama</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/reconstrucaoAreolaMamilo")
                    }
                } fullwidth>Reconstrução da aréola e mamilo</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/intercorrencias")
                    }
                } fullwidth>Possíveis Intercorrências ou complicações</Button>
            </div>
        </>
    )
}