import React from 'react'
import { Title, SubTitle } from '../../components'

export default function(){
    return (
        <>
            <Title>O que é?</Title>

            <div className="row mt-5 text-white">
                <div className="col">
                    <SubTitle>Cirurgia para substituição do expansor por prótese de silicone</SubTitle>
                    <p>Trata-se de uma segunda cirurgia na reconstrução da mama, na qual é feita a retirada do expansor e é colocada uma prótese de silicone de tamanho semelhante ou menor do que o expansor.</p>
                </div>
            </div>
        </>
    )
}