import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    Title,
    SubTitle, Button
} from '../../components'

import styles from './styles.module.css'

export default function (){
    const history = useHistory()
    return (
        <>
            <Title>Introdução</Title>
            <div className={styles.content + " row"}>
                <div className="col p-3 m-3 text-justify">
                    <p>
                        Este aplicativo foi elaborado para levar orientação de qualidade e facilitada às pacientes submetidas à reconstrução de mama. Servirá como guia para auxiliar nos cuidados pós-operatórios, sem substituir a importância das orientações presenciais.
                    </p>
                    <p>
                        A reconstrução de mama é um direito das mulheres e a informação é uma importante ferramenta nesse momento tão delicado.
                    </p>
                    <SubTitle>Para que realizar a reconstrução?</SubTitle>
                    <p>
                        O objetivo da reconstrução mamária é recuperar a autoestima e a qualidade de vida da paciente, restaurando o máximo possível o formato, a aparência e o volume após a cirurgia para remoção do câncer da mama.
                    </p>

                    <SubTitle>Quando?</SubTitle>
                    <p>
                        A reconstrução pode ser realizada na mesma cirurgia da retirada do tumor (reconstrução imediata) ou após meses e até anos (reconstrução tardia).
                    </p>

                    <SubTitle>Como é a cirurgia para retirada do tumor?</SubTitle>
                    <p>
                        A cirurgia para remoção do tumor é uma das etapas principais do tratamento do câncer de mama, e é realizada pela equipe de Mastologia. Dependendo de diferentes fatores, como tamanho da mama, tipo e classificação da gravidade do tumor (também conhecida como estadiamento), podem ser indicados diferentes tipos de cirurgia:
                    </p>
                    <p>
                        <strong>Mastectomia</strong>: quando toda a mama é retirada.
                    </p>
                    <p>
                        <strong>Adenomastectomia ou mastectomia subcutânea</strong>: quando se retira todo otecido mamário, mas a pele é preservada (incluindo, algumas vezes, a preservação do mamilo e da aréola).
                    </p>
                    <p>
                        <strong>Quadrantectomia</strong>: quando um quadrante (cerca de um quarto) da mama é removido.
                    </p>
                    <p>
                        <strong>Setorectomia</strong>: quando se remove apenas o setor da mama em que o tumor está localizado, retirando menos que um quarto da mama.
                    </p>
                    <p>
                        <strong>Nodulectomia</strong>: quando se retira apenas o nódulo (tumor).
                    </p>

                    <p>
                        Em alguns casos, a axila também precisa ser operada, procedimento de linfadenectomia, para retirada dos “gânglios” (linfonodos), pois eles também podem estar comprometidos pela doença.
                    </p>

                    <SubTitle>Como é a reconstrução?</SubTitle>
                    <p>
                        A reconstrução de mama é realizada pela equipe de Cirurgia Plástica e geralmente é feita em algumas etapas (diferentes cirurgias, com um intervalo de alguns meses entre elas). Muitas vezes é necessário operar também a outra mama, para que fiquem parecidas (esse procedimento é conhecido como simetrização).
                    </p>
                    <p>
                        Pode ser realizada com implantes (próteses de silicone e expansores de tecido) ou com tecido da própria paciente (retalhos). Também pode haver associação das duas coisas (retalhos e implantes).
                    </p>
                    <p>
                        A escolha do momento da reconstrução (imediata ou tardia) e da técnica a ser utilizada deve sempre ser discutida entre o mastologista, o cirurgião plástico e a paciente. Vários fatores são levados em consideração para escolher o melhor tratamento:
                    </p>
                    
                    <ul>
                        <li>Tipo de cirurgia oncológica (mastectomia, adenomastectomia, quadrantectomia, setorectomia)</li>
                        <li>Tratamentos complementares (quimioterapia, radioterapia, imunoterapia, hormonioterapia)</li>
                        <li>Biotipo da paciente (estrutura do corpo da paciente)</li>
                        <li>Presença de comorbidades (outras doenças existentes) e cirurgias já realizadas</li>
                        <li>Vontade da paciente</li>
                    </ul>

                    <SubTitle>Quais os tipos de reconstrução de mama?</SubTitle>

                    <p>
                        Pergunte ao seu médico qual foi (ou qual será) o tipo da sua reconstrução para prosseguir.
                    </p>

                    <div className="text-center mt-5">
                        <Button onClick={() => {
                            history.push("mainmenu")
                        }}>Avançar</Button>
                    </div>
                </div>
            </div>
        </>
    )
}