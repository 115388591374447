import React from 'react'

import {
    Title
} from '../../components'

import img1 from '../../assets/img1.jpg'
import img2 from '../../assets/img2.jpg'

export default function(){
    return (
        <>
            <Title>O que é?</Title>

            <div className="row mt-5 text-white">
                <div className="col">
                    <p>
                        Após a mastectomia é colocado um expansor de tecidos embaixo dos músculos do tórax. O expansor se assemelha a uma prótese de mama vazia que é ligada à uma válvula através de um pequeno tubo. Esta válvula será percebida como uma pequena peça arredondada embaixo da pele. O expansor é preenchido pouco a pouco, com soro fisiológico, através dessa válvula.
                    </p>

                    <img className="img-fluid mt-3 mb-3" src={img1} alt="Expansor de Tecidos" />
                    <img className="img-fluid" src={img2} alt="Expansor de Tecidos" />
                </div>
            </div>
        </>
    )
}