import React from 'react'

import styles from './styles.module.css'

export default function({ children, onClick, fullwidth }){

    const className = fullwidth ? styles.fullwidth : styles.button
    return (
        <button className={className} onClick={onClick}>{ children }</button>
    )
}