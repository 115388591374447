import React from 'react'
import { SubTitle, Title } from '../../components'

export default function(){
    return (
        <>
            <Title>Reconstrução Aréola e Mamilo</Title>

            <div className="row mt-5">
                <div className="col text-white">
                    <p className="mt-4">A reconstrução da aréola e do mamilo é a última etapa da reconstrução da mama. A aréola pode ser reconstruída utilizando enxerto de pele da aréola da outra mama ou enxerto de pele da região da virilha.</p>
                    <p className="mt-4">O mamilo pode ser reconstruído com parte do mamilo da outra mama ou com um retalho da pele da aréola da mesma mama, dobrada sobre si mesmo.</p>
                    <p className="mt-4">Também, pode ser realizada uma tatuagem desenhando a aréola e o mamilo.</p>

                    <SubTitle>Cuidados com o enxerto da aréola:</SubTitle>

                    <p className="mt-4">Após a cirurgia será realizado um curativo especial sobre a aréola (chamado curativo de Brown) que só será retirado pela equipe médica, não devendo ser molhado no banho.</p>
                    <p className="mt-4">É normal a pele do enxerto descascar um pouco e mudar de cor. Com o tempo irá melhorar.</p>
                </div>
            </div>
        </>
    )
}