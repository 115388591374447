import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    Button,
    SubTitle,
    Title
} from '../../components'

export default function(){
    const history = useHistory()
    return (
        <>
            <Title>Escolha uma opção</Title>
            <div className="row">
                <div className="col text-white mt-5">
                    <SubTitle>Reconstrução mamária com retalhos (associada ou não a implantes)</SubTitle>

                    <p>Quando se realiza a reconstrução com tecidos da própria paciente (pele, gordura, músculos e/ou o que sobrou da mama após a retirada do tumor).</p>
                </div>
            </div>
            <div className="text-center mt-5">
                <Button onClick={() => {
                    history.push("/retalholocal")
                }} fullwidth>Retalho Local e/ou Regional</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={() => {
                    history.push("/retalhodorsal")
                }} fullwidth>Retalho com o músculo grande dorsal (latíssimo do dorso)</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/retalhotram")
                    }
                } fullwidth>Retalho TRAM (Músculo Reto Abdominal)</Button>
            </div>
            <div className="text-center mt-5">
                <Button onClick={
                    () => {
                        history.push("/retalhomicrocirurgico")
                    }
                } fullwidth>Retalho Microcirúrgico</Button>
            </div>
        </>
    )
}