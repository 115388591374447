import React from 'react'
import { Title } from '../../components'

import img5 from '../../assets/img5.jpg'

export default function(){
    return (
        <>
            <Title>O que é?</Title>

            <div className="row mt-5 text-white">
                <div className="col">
                    <p>O retalho TRAM usa o músculo da barriga (reto do abdome), gordura e pele da barriga para reconstruir a mama. O retalho todo sai da região abdominal e passa por debaixo da pele, acima do umbigo, até a região operada da mama. É necessário o uso de uma tela para fortalecer a parede abdominal, no local que ficou sem o músculo.</p>

                    <img className="img-fluid mt-5" src={img5} alt="Retalho TRAM" />
                </div>
            </div>
        </>
    )
}