import React from 'react'
import { Title } from '../../components'

export default function(){
    return (
        <>
            <Title>O que é?</Title>

            <div className="row mt-5 text-white">
                <div className="col">
                    <p>Geralmente utilizados quando é retirado apenas um setor ou quadrante da mama. A reconstrução pode utilizar o que sobrou da mama ou pele e gordura próximos ao local de onde foi retirado o tumor.</p>
                </div>
            </div>
        </>
    )
}