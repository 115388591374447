import React from 'react'

import {
    SubTitle,
    Title
} from '../../components'

export default function(){
    const intercorrencias = [
        {
            title: "Acúmulo de Líquidos ou secreções",
            text: "Líquidos podem acumular embaixo da pele, sendo claros (seroma), sanguinolentos (hematoma) ou purulentos (infecção ou abscesso). Eles podem vazar sozinhos ou precisar de punção (retirada pelo médico com uma pequena agulha). Seu médico deverá avaliar e indicar o melhor tratamento."
        },
        {
            title: "Infecção",
            text: "Pode aparecer dor, calor e vermelhidão na pele. Observar a presença de febre (temperatura maior que 37,8 °C). Seu médico deverá avaliar e indicar o melhor tratamento."
        },
        {
            title: "Abertura dos pontos (Deiscência)",
            text: "Pode ocorrer rompimento dos pontos (região pequena ou grande). Aberturas pequenas geralmente se resolvem com curativos. Deve-se avisar a equipe médica e não fazer esforço."
        },
        {
            title: "Roxos na pele (Esquimoses ou hematomas)",
            text: "Os roxos podem ser superficiais (equimose) e desaparecerem com o passar dos dias, ou mais profundos (hematomas)."
        },
        {
            title: "Necrose (ou isquemia)",
            text: "Alguma parte da pele pode ter dificuldade em receber o sangue e ficar mais branca e fria ou arroxeada. Comunique seu médico."
        },
        {
            title: "Trombose",
            text: "Sentir dor súbita em uma das pernas, com endurecimento, inchaço e sensação de peso pode ser um sinal de trombose. Procure atendimento médico imediato."
        },
        {
            title: "Falta de ar",
            text: "Se sentir falta de ar ou dor no peito e coração muito acelerado, procure atendimento médico imediato."
        },
        {
            title: "Náuseas ou vômitos",
            text: "Sentir leves náuseas ou enjoos pode ser normal nos primeiros dias e deve melhorar com medicação . Se forem intensos e com vômitos, entre em contato com a equipe médica."
        }
    ]
    return (
        <>
            <Title>Intercorrências e Complicações</Title>

            <div className="row mt-5">
                <div className="col text-white">
                    {
                        intercorrencias.map(intercorrencia => (
                            <div key={intercorrencia.title}>
                                <SubTitle>{intercorrencia.title}</SubTitle>
                                <p>
                                    {intercorrencia.text}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="row mt-5">
                <div className="col text-white">
                    <p>A recuperação no pós-operatório pode levar várias semanas para melhora do inchaço, da dor e da forma e posição da mama. Siga as recomendações do seu cirurgião plástico e compareça às consultas de retorno como previsto.</p>
                    <p>Entre em contato com seu médico se apresentar alguma outra alteração, complicação ou dúvida.</p>
                    <p>Seguir as recomendações da equipe médica é fundamental para o sucesso da cirurgia.</p>
                </div>
            </div>
        </>
    )
}