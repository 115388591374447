import React from 'react'
import { Title } from '../../components'

export default function(){
    return (
        <>
            <Title>O que é?</Title>
            <p className="text-white mt-5">
                Esse tipo de reconstrução pode ser feita se, durante a retirada do tumor, foi
                deixado pele, gordura e músculo suficiente para cobrir essa prótese. Este
                procedimento resulta em um volume imediato e final da mama. A prótese
                geralmente é colocada embaixo dos músculos do tórax, podendo ser colocada
                acima dos músculos em alguns casos.
            </p>
        </>
    )
}