import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    Button
} from '../../components'

import styles from './styles.module.css'

export default function (){
    const history = useHistory()
    return (
        <>
            <div className="row">
                <div className="col mt-5 mb-5">
                    <p className={styles.author}>Evelyne Borges de Mattos Andrade</p>
                    <p className={styles.author}>Daniela Francescato Veiga</p>
                    <p className={styles.author}>Edgard da Silva Garcia</p>
                </div>
            </div>
            <div className="row">
                <div className="col text-center mt-5">
                    <Button onClick={() => history.push("/intro")}>Início</Button>
                </div>
            </div>    
        </>
    )
}